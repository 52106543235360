










































































import Vue from "vue";
import Layout from "@/router/layouts/register.vue";
import _ from "lodash";
import router from "@/router";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import RegistrationStepper from "@/components/registration-stepper.vue";
import ArrowBack from "@iconify-icons/ion/arrow-back";

export default Vue.extend({
  components: {
    Layout,

    RegistrationStepper,
  },

  data() {
    return {
      currentStep: 3,
      icon: {
        arrowBack: ArrowBack,
      },
      sendLoader: false,
      interval: null,
    };
  },

  async mounted() {
    this.saveData();
    this.track();
  },

  created() {
    this.interval = setInterval(() => this.checkIfEmailWasVerified(this.userEmail), 7000);
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },

  computed: {
    ...mapGetters("registerModule", {
      userEmail: "GET_USER_EMAIL",
      userIsLoggedIn: "EXIST_USER_WITH_EMAIL",
      userData: "GET_USER_DATA",
      companyData: "GET_COMPANY_DATA",
      venueData: "GET_VENUE_DATA",
      registrationResourceId: "GET_REGISTER_FILLED_DATA_ID",
      newUserID: "GET_NEW_REGISTERED_USER_ID",
    }),
    ...mapGetters("userModule", {
      loggedUser: "GET_LOGGED_USER",
    }),
    ...mapGetters("emailsModule", {
      isUserEmailVerified: "GET_IS_USER_EMAIL_VERIFIED",
    })
  },

  methods: {
    ...mapActions("emailsModule", {
      sendEmail: "SEND_REGISTRATION_EMAIL",
      checkifIsUserActive: "IS_USER_ACTIVE",
    }),
    continueToStepThree() {
      router.push("/register/step-3?lang=" + this.$i18n.locale);
    },

    sendAgain() {
      this.sendLoader = true;
      const userData = this.userData;
      this.sendEmail({
        email: userData.email,
        clientZoneUserId: this.newUserID,
        userIsLoggedIn: false,
      });
      setTimeout(() => {
        this.sendLoader = false;
      }, 750);
    },

    saveData() {
      const registerData = {
        userIsLoggedIn: this.userIsLoggedIn,
        userData: this.userData,
        newUserId: this.newUserID,
        companyData: this.companyData,
        venueData: this.venueData,
        registrationResourceId: this.registrationResourceId,
        loggedUser: this.loggedUser,
      };
      localStorage.setItem("registrationData", JSON.stringify(registerData));
    },

    track() {
      this.$gtag.event("registration", {
        event_category: "registration",
        event_label: "step",
        value: "4",
      });
    },
    async checkIfEmailWasVerified(email: string): Promise<void> {
      if (email) {
        await this.checkifIsUserActive(email);
      }

      if (this.isUserEmailVerified === true) {
        clearInterval(this.interval);
        this.interval = null;
        router.push("/register/step-5");
      }
    },
  },
});
